import { Fragment } from "react";

import useAccountCreationExpiredPassword from "./UseAccountCreationExpiredPassword";


import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import ExpiredPasswordForm from "../../../../../../../common/components/forms/login/expiredPassword/ExpiredPasswordForm";

import Constants from "../../../../../../../common/utils/Constants";

const AccountCreationExpiredPassword = () => {
  const {
    isLoading,
    loginErrorPopupState,
    username,
    onCancelClicked,
    onCloseLoginErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationExpiredPassword();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <Headings.H3>Password Expired</Headings.H3>
        <p>Your password has expired and must be changed</p>
        <ExpiredPasswordForm
          submitButtonText={'Submit'}
          secondaryButtonText={'Cancel'}
          userName={username}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onCancelClicked} />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationExpiredPassword;