import { API_NAMES, initApi } from "../../../utils/HttpApiHelper";

const postAccountForgotUsernameData = (emailAddress, state, setState) => {
  if (emailAddress) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/ForgotUsername`;

    return api?.executeObject ? api.executeObject(url, 'POST', { emailAddress }) : null;
  }
};

const AccountForgotUsernameData = {
  postAccountForgotUsernameData
};

export default AccountForgotUsernameData;