import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import useLandingLoginForm from "./UseLandingLoginForm";

import Input from "../../../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

const LandingLoginForm = ({ onSubmitFormCallback, onForgotUsernameClicked, onForgotPasswordClicked, onCreateALoginClicked }) => {
  const {
    formState,
    errorState,
    hidePasswordState,
    handleSubmit,
    onValueChanged,
    onToggleHideShowPassword
  } = useLandingLoginForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label={'Username'}
            name={'username'}
            value={formState.username}
            error={errorState.username}
            message={errorState.username}
            autoFocus={true}
            onChange={(value) => onValueChanged('Username', 'username', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <FontAwesomeIcon icon={hidePasswordState !== true ? faEye : faEyeSlash} onClick={onToggleHideShowPassword} style={{ cursor: 'pointer', marginRight: hidePasswordState !== true ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label={'Password'}
            name={'password'}
            value={formState.password}
            error={errorState.password}
            message={errorState.password}
            type={hidePasswordState !== true ? "text" : "password"}
            onChange={(value) => onValueChanged('Password', 'password', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Login</PrimaryButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-4">
          <SecondaryButton onClick={onForgotUsernameClicked}>Forgot Username</SecondaryButton>
        </div>
        <div className="col-xs-12 col-sm-4">
          <SecondaryButton onClick={onForgotPasswordClicked}>Forgot Password</SecondaryButton>
        </div>
        <div className="col-xs-12 col-sm-4">
          <SecondaryButton onClick={onCreateALoginClicked}>Create a Login</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default LandingLoginForm;