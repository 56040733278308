import { useState } from 'react';

import AccountChangePasswordData from './AccountChangePasswordData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_CHANGE_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountChangePasswordData = () => {
  const [accountChangePasswordState, setAccountChangePasswordState] = useState(INITIAL_ACCOUNT_CHANGE_PASSWORD_STATE);

  const postAccountChangePassword = (href, newPassword, recoveryCode) => {
    return AccountChangePasswordData.postAccountChangePasswordData(href, newPassword, recoveryCode, accountChangePasswordState, setAccountChangePasswordState);
  };

  const resetAccountChangePasswordState = () => {
    setAccountChangePasswordState(INITIAL_ACCOUNT_CHANGE_PASSWORD_STATE);
  };

  return {
    accountChangePasswordState,
    resetAccountChangePasswordState,
    postAccountChangePassword
  };
};

export default useAccountChangePasswordData;