import { initCustomApi } from "../AccountApiHelper";

const postAccountSetMFAData = (accessToken, email, textMessage, phoneNumber, state, setState) => {
  if (accessToken) {
    const api = initCustomApi(state, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/SetMFA`;
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };
    const payload = {
      email: email,
      textMessage: textMessage,
      phoneNumber: phoneNumber
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload) : null;
  }
};

const AccountMFAData = {
  postAccountSetMFAData
};

export default AccountMFAData;