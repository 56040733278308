import useSessionDebugToggle from "./UseSessionDebugToggle";

import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import Headings from "../../../../../common/components/headings/Headings";
import ActionIntraPageButton from "../../../../../common/components/buttons/ActionIntraPageButton";

import style from '../../LandingPages.module.css';

const SessionDebugToggle = () => {
  const {
    isSessionDebugTimerEnabled,
    onGoToLandingClicked,
    onToggleSessionDebugTimer
  } = useSessionDebugToggle();

  return (
    <div className="row usas-extra-bottom-margin">
      <div className="col-xs-12 col-md-4" />
      <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
        <Headings.H3>Session Debug Timer</Headings.H3>
        <div className="row">
          <div className="col-xs-12">
            <YesNoSwitch
              label={'Enable Session Debug Timer?'}
              name={'useNewSessionDebug'}
              checked={isSessionDebugTimerEnabled === true}
              onChange={() => onToggleSessionDebugTimer()} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ActionIntraPageButton type="button" onClick={onGoToLandingClicked}>Go To Landing Page</ActionIntraPageButton>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-4" />
    </div>
  );
};

export default SessionDebugToggle;