import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';
import NavLinks from './NavLinks';

import useVendorManagementData from '../../state/vendorManagement/UseVendorManagementData';

const useManageVendorsUserGroupsAdd = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { vendorManagementState, putVendorManagementUserGroup } = useVendorManagementData();

  function createUserGroupsArray(userAccountId, existingUserGroups, newUserGroups) {
    const userGroupsArray = [...existingUserGroups];

    for (const userGroup of newUserGroups) {
      if (!userGroupsArray.find((x) => x.userGroupId === userGroup.id)) {
        userGroupsArray.push({ userAccountId, userGroupId: userGroup.id, userGroupName: userGroup.name });
      }
    }

    return userGroupsArray;
  };

  const onSubmitFormCallback = (formState) => {
    const userAccountId = location.state.vendor.UserAccountId

    const vendorIdx = vendorManagementState.arrayData.findIndex((x) => x.UserAccountId === userAccountId);
    const putVendorManagementUserGroupPromise = 
      putVendorManagementUserGroup(
        userAccountId, 
        createUserGroupsArray(userAccountId, vendorManagementState.arrayData[vendorIdx].UserGroups || [], formState.userGroup));

    if (putVendorManagementUserGroupPromise ?? false) {
      putVendorManagementUserGroupPromise.then((newState) => {
        if (newState ?? false) {
          navigate(NavLinks.MANAGE_VENDORS_USER_GROUP, { state: { vendor: location.state.vendor }});
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.MANAGE_VENDORS_USER_GROUP, { state: { vendor: location.state.vendor } });
  };

  return {
    onSubmitFormCallback,
    onBackClicked
  }
}
export default useManageVendorsUserGroupsAdd;