import { Fragment } from 'react';

import ManageVendorsGridLarge from './ManageVendorsGridLarge';
import ManageVendorsGridSmall from './ManageVendorsGridSmall';

const ManageVendorsGrid = ({ data, isLoading, onAddVendorPerson, onManageUserGroups, onAddShoppingCart }) => (
  <Fragment>
    <div className='row'>
      <div className="col-xs-12">
        <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>
      </div>
    </div>
    <ManageVendorsGridLarge
      data={data}
      isLoading={isLoading}
      onAddVendorPerson={onAddVendorPerson}
      onManageUserGroups={onManageUserGroups}
      onAddShoppingCart={onAddShoppingCart}
    />
    <ManageVendorsGridSmall
      data={data}
      isLoading={isLoading}
      onAddVendorPerson={onAddVendorPerson}
      onManageUserGroups={onManageUserGroups}
      onAddShoppingCart={onAddShoppingCart} 
    />
  </Fragment>
);

export default ManageVendorsGrid;