import React from 'react';

import global from '../../../common/components/GlobalStyle.module.css';
import DeleteIcon from '../../../common/components/icons/DeleteIcon';

const GridRow = ({ data, onDeleteUserGroup }) => (
  <tr>
    <td>{data.UserGroupName}</td>
    <td style={{ float: 'right' }}>
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteUserGroup(data)}>
        <DeleteIcon toolTipText='Delete User from Group' />
      </button>
    </td>
  </tr>
);

const ManageVendorsUserGroupsGridLarge = ({ data, isLoading, onDeleteUserGroup }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>User Groups</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((data, i) => (
            <GridRow
              key={i}
              data={data}
              onDeleteUserGroup={onDeleteUserGroup}
            />
          )) : (
            <tr>
              <td colSpan="2">No User Groups Assigned</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default ManageVendorsUserGroupsGridLarge;