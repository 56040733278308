import { useEffect, useState } from 'react';

import NavLinks from './NavLinks';

import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useVendorManagementData from '../../state/vendorManagement/UseVendorManagementData';

const useManageVendors = () => {
  const navigate = useNavigate();

  //TODO: change case to camelCase coming from API
  const { vendorManagementState, getVendorManagement, postVendorApiRegistrtation } = useVendorManagementData();
  
  const onAddVendorPerson = (vendor) => {
    navigate(NavLinks.MANAGE_VENDORS_DETAIL, { state: { vendor: vendor } });
  }

  const onManageUserGroups = (vendor) => {
    navigate(NavLinks.MANAGE_VENDORS_USER_GROUP, { state: { vendor: vendor } });
  } 

  const onAddShoppingCart = (vendor) => {
    const postVendorApiRegistrtationPromise =
      postVendorApiRegistrtation(vendor.VendorId, vendor.PersonId);

    if (postVendorApiRegistrtationPromise ?? false) {
      postVendorApiRegistrtationPromise.then((newState) => {
        if (newState ?? false) {
        }
      }).catch((e) => {
        //context error
      });
    }
  }

  useEffect(() => {
    if (vendorManagementState.isArrayLoaded === false && vendorManagementState.isArrayLoading === false) {
        getVendorManagement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorManagementState])

  return {
    vendorManagementState,
    onAddVendorPerson,
    onManageUserGroups,
    onAddShoppingCart
  }
}
export default useManageVendors;