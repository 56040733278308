import { Fragment } from "react";
import useManageVendorsUserGroupsAdd from "./UseManageVendorsUserGroupsAdd";

import UserGroupAddForm from "../accountManagement/components/forms/UserGroupAddForm";

import Headings from "../../../common/components/headings/Headings";

const ManageVendorsUserGroupsAdd = () => {
  const {
    onSubmitFormCallback,
    onBackClicked
   } = useManageVendorsUserGroupsAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add User Group Assignment to a Vendor</Headings.H3>
        </div>
      </div>
      <UserGroupAddForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
    </Fragment>);
}
export default ManageVendorsUserGroupsAdd;