import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import useChangePasswordForm from "./UseChangePasswordForm";

import Input from "../../../inputs/Input";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";

import style from '../LoginForms.module.css';

const ChangePasswordForm = ({ submitButtonText = 'Save', secondaryButtonText = 'Back', verticalButtons = false,
  userName, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    hidePasswordState,
    handleSubmit,
    updateFormState,
    onToggleHideShowPassword,
    onNewPasswordChanged,
  } = useChangePasswordForm(onSubmitFormCallback, userName);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Recovery Code*'
            name="recoveryCode"
            value={formState.recoveryCode}
            error={errorState.recoveryCode}
            message={errorState.recoveryCode}
            onChange={(value) => { updateFormState('recoveryCode', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          Password Requirements
          <p style={{ fontSize: 14 }}>
            <FontAwesomeIcon color={requirementsState.password10Characters === true ? 'green' : '#c1c6c8'} icon={requirementsState.password10Characters === true ? faCheckCircle : faCircle}></FontAwesomeIcon> No less than 10 characters<br />
            <FontAwesomeIcon color={requirementsState.passwordNumber === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordNumber === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 number<br />
            <FontAwesomeIcon color={requirementsState.passwordSpecialCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordSpecialCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 special character<br />
            <FontAwesomeIcon color={requirementsState.passwordLowercaseCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordLowercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 lowercase character<br />
            <FontAwesomeIcon color={requirementsState.passwordUppercaseCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordUppercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 uppercase character<br />
            <FontAwesomeIcon color={requirementsState.passwordNotEqualToUsername === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordNotEqualToUsername === true ? faCheckCircle : faCircle}></FontAwesomeIcon> Not equal to username
          </p>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <FontAwesomeIcon icon={hidePasswordState.hideNewPassword === false ? faEye : faEyeSlash} onClick={() => onToggleHideShowPassword('hideNewPassword')} style={{ cursor: 'pointer', marginRight: hidePasswordState.hideNewPassword === false ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label='New Password*'
            name="newPassword"
            value={formState.newPassword}
            error={errorState.newPassword}
            message={errorState.newPassword}
            type={hidePasswordState.hideNewPassword === false ? 'text' : 'password'}
            onChange={(value) => { onNewPasswordChanged(value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <FontAwesomeIcon icon={hidePasswordState.hideConfirmPassword === false ? faEye : faEyeSlash} onClick={() => onToggleHideShowPassword('hideConfirmPassword')} style={{ cursor: 'pointer', marginRight: hidePasswordState.hideConfirmPassword === false ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label='Confirm New Password*'
            name="confirmPassword"
            value={formState.confirmPassword}
            error={errorState.confirmPassword}
            message={errorState.confirmPassword}
            type={hidePasswordState.hideConfirmPassword === false ? 'text' : 'password'}
            onChange={(value) => { updateFormState('confirmPassword', value) }} />
        </div>
      </div>
      <div className="row">
        <div className={["col-xs-12 usas-extra-top-margin", style.FormButtons, verticalButtons ? style.Vertical : ''].join(' ')}>
          <PrimaryButton isDisabled={enableSaveButtonState !== true} type="submit">{submitButtonText}</PrimaryButton>
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;