import validate from './MFAEnrollmentFormValidation';

import UseForm from "../../../../utils/UseForm";

const INITIAL_FORM_STATE = {
  useEmail: false,
  useTextMessage: false,
  phoneNumber: ''
};

const useMFAEnrollmentForm = (onSubmitFormCallback) => {
  const { formState, errorState, handleSubmit, setFormState, setIsDirty
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onMFAOptionChanged = (useEmail, useTextMessage) => {
    setFormState({
      ...formState,
      useEmail,
      useTextMessage,
      phoneNumber: useTextMessage === true ? formState.phoneNumber : '+1'
    });

    setIsDirty(true);
  };

  const onMFAPhoneNumberChanged = (value) => {
    setFormState({
      ...formState,
      useEmail: false,
      useTextMessage: true,
      phoneNumber: value
    });

    setIsDirty(true);
  };

  return {
    formState,
    errorState,
    handleSubmit,
    onMFAOptionChanged,
    onMFAPhoneNumberChanged
  };
};

export default useMFAEnrollmentForm;