import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import { AC_STEP_MFA, AC_STEP_ONE_TIME_PASSWORD, AC_STEP_USER_INFO } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useAccountLoginData from "../../../../../../../common/state/account/accountLogin/UseAccountLoginData";

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationValidateEmail = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, accountCreationStep2State, postAccountCreationStep2, updateAccountCreationStep,
    resetAccountCreationStep2State, resetAccountCreationState
  } = useAccountCreationData();
  const { accountLoginState, postAccountLogin } = useAccountLoginData();
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onBackClicked = () => {
    updateAccountCreationStep(AC_STEP_USER_INFO);
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountCreationStep2State();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onReturnToLoginClicked = () => {
    resetAccountCreationState();
  };

  const onSubmitFormCallback = (formState) => {
    const stepObj = createStepObj(formState, accountCreationState.userInfoData);

    const postAccountCreationStep2Promise = postAccountCreationStep2(stepObj);

    if (postAccountCreationStep2Promise ?? false) {
      postAccountCreationStep2Promise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData.errorMessage === null) {
            postAccountLoginHelper(accountCreationState.userInfoData);
          } else {
            setAPIErrorPopupState({
              ...apiErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          }
        }
      }).catch((e) => {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  function createStepObj(formState, userInfoData) {
    return {
      personId: userInfoData.personId || undefined,
      firstName: userInfoData.firstName.trim(),
      preferredName: userInfoData.preferredName ? userInfoData.preferredName.trim() : '',
      middleName: userInfoData.middleName ? userInfoData.middleName.trim() : '',
      lastName: userInfoData.lastName.trim(),
      emailAddress: userInfoData.email.trim(),
      birthDate: userInfoData.birthDate,
      memberId: userInfoData.memberId || '',
      password: userInfoData.password,
      confirmPassword: userInfoData.confirmPassword,
      verificationCode: formState.verificationCode.trim(),
      verificationCodeFromServer: userInfoData.verificationCode
    };
  }

  function postAccountLoginHelper(userInfoData) {
    const postAccountLoginPromise = postAccountLogin(userInfoData.email, userInfoData.password);

    if (postAccountLoginPromise ?? false) {
      postAccountLoginPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accountLockedOut === true) {
            const minutes = Math.ceil(newState.objData?.secondsUntilUnlock / 60);

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`
            });
          } else if (newState.objData?.mustEnrollInMFA === true) {
            const loginData = {
              accessToken: newState.objData?.accessToken,
              emailAddress: newState.objData?.emailAddress
            };

            updateAccountCreationStep(AC_STEP_MFA, loginData, 'loginData');
          } else if (newState.objData?.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The username and/or password is invalid.'
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  }

  return {
    isLoading: accountCreationStep2State.isObjLoading || accountLoginState.isObjLoading,
    apiErrorPopupState,
    loginErrorPopupState,
    username: accountCreationState.userInfoData?.email || '',
    onSubmitFormCallback,
    onBackClicked,
    onCloseAPIErrorPopupClicked,
    onReturnToLoginClicked
  };
};

export default useAccountCreationValidateEmail;