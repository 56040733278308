import { formatDate } from '../../../common/utils/DateFunctions';
import FamilyIcon from '../../../common/components/icons/FamilyIcon';
import PersonIcon from '../../../common/components/icons/PersonIcon';
import ShoppingCartIcon from '../../../common/components/icons/ShoppingCartIcon';
import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ vendor, onAddVendorPerson, onManageUserGroups, onAddShoppingCart }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{vendor.VendorName}</span>&nbsp;
      <div className={global.IconButton}>
        {vendor.UserAccountId &&
          <>
            <button className={global.IconButtonMargin}
              type="button"
              disabled={vendor.CartItemCount > 0}
              onClick={() => onAddShoppingCart(vendor)}>
              <ShoppingCartIcon
                toolTipText='Add Shopping Cart Item'
                showCartCount={vendor.CartItemCount > 0}
                cartCount={vendor.CartItemCount}
              />
            </button>
            <button className={global.IconButtonMargin}
              type="button"
              onClick={() => onManageUserGroups(vendor)}>
              <FamilyIcon toolTipText='Manage User Groups' />
            </button>
          </>
        }
        <button className={global.IconButtonMargin}
          type="button"
          onClick={() => onAddVendorPerson(vendor)}>
          <PersonIcon toolTipText='Select Vendor Person' />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>First Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{vendor.FirstName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Last Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{vendor.LastName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Groups Assigned</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{vendor.GroupsAssigned > 0 ? 'Yes' : 'No'}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{vendor.ExpirationDate ? formatDate(vendor.ExpirationDate) : ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Paid?</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{vendor.HasPaid ? 'Yes' : 'No'}</div>
      </div>
    </div>
  </div>
);

const ManageVendorsSmallGrid = ({ data, isLoading, onAddVendorPerson, onManageUserGroups, onAddShoppingCart }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((vendor, i) => (
          <GridRow key={i} vendor={vendor} 
            onAddVendorPerson={onAddVendorPerson} 
            onManageUserGroups={onManageUserGroups} 
            onAddShoppingCart={onAddShoppingCart} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Vendors</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ManageVendorsSmallGrid;