import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import useVendorManagementData from '../../state/vendorManagement/UseVendorManagementData';
import useAccountManagementUserGroupData from '../../state/accountManagement/accountManagementUserGroup/UseAccountManagementUserGroupData';

import NavLinks from './NavLinks';

const useManageVendorsUserGroups = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { vendorManagementState, getVendorManagement } = useVendorManagementData();
  const { accountManagementUserGroupState, deleteAccountManagementUserGroup } = useAccountManagementUserGroupData();
  const [state, setState] = useState({ userGroups: [] });

  const onAddUserGroup = () => {
    navigate(NavLinks.MANAGE_VENDORS_USER_GROUP_ADD, { state: { vendor: location.state.vendor } });
  }

  const onDeleteUserGroup = (data) => {
    if (data.UserAccountGroupId > 0) {
      const deleteAccountManagementUserGroupPromise = deleteAccountManagementUserGroup(data.UserAccountGroupId);

      if (deleteAccountManagementUserGroupPromise ?? false) {
        deleteAccountManagementUserGroupPromise.then((newState) => {
          if (newState ?? false) {
            const userAccountId = accountManagementUserGroupState.objData?.userAccountId;
            const getVendorManagementPromise = getVendorManagement(userAccountId);

            if (getVendorManagementPromise ?? false) {
              getVendorManagementPromise.catch((e) => {
                //context error
              });
            }
          }
        }).catch((e) => {
          //state error
        });
      }
    }
  }

  const onBack = () => {
    navigate(NavLinks.MANAGE_VENDORS);
  }

  useEffect(() => {
    if (vendorManagementState.isArrayLoaded === true) {
        const vendorIdx = vendorManagementState.arrayData.findIndex((x) => x.UserAccountId === location.state.vendor.UserAccountId);
        setState({ userGroups: vendorManagementState.arrayData[vendorIdx].UserGroups });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorManagementState])


  return {
    //vendorManagementState,
    accountManagementUserGroupState,
    data: state.userGroups || [],
    onAddUserGroup, 
    onDeleteUserGroup, 
    onBack}
}
export default useManageVendorsUserGroups;