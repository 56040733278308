import { useEffect } from "react";

import validate from './ForgotPasswordFormValidation';

import UseForm from "../../../../utils/UseForm";

const INITIAL_FORM_STATE = {
  username: ''
};

const useForgotPasswordForm = (onSubmitFormCallback) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onValueChanged = (componentLabel, componentName, componentValue) => {
    if (componentValue.trim().length > 0) {
      updateFormState(componentName, componentValue);
      setErrors({ ...errorState, [componentName]: undefined });
    } else {
      updateFormState(componentName, componentValue);
      setErrors({ ...errorState, [componentName]: `${componentLabel} is required` });
    }
  };

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    onValueChanged
  };
};

export default useForgotPasswordForm;