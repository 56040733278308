import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useAccountOneTimePasswordData from "../../../../../../../common/state/account/accountOneTimePassword/UseAccountOneTimePasswordData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationOneTimePassword = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, resetAccountCreationState } = useAccountCreationData();
  const { accountOneTimePasswordState, postAccountOneTimePassword, resetAccountOneTimePasswordState
  } = useAccountOneTimePasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountOneTimePasswordState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const href = accountCreationState.loginData.href;
    const oneTimePassword = formState.oneTimePassword;

    const postAccountOneTimePasswordPromise = postAccountOneTimePassword(href, oneTimePassword);

    if (postAccountOneTimePasswordPromise ?? false) {
      postAccountOneTimePasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The one-time password is invalid.'
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountOneTimePasswordState.isObjLoading,
    device: accountCreationState.loginData?.device || '',
    loginErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationOneTimePassword;