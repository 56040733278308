import { Fragment } from "react";
import useManageVendorsDetail from "./UseManageVendorsDetail";
import Input from "../../../common/components/inputs/Input";

import Headings from "../../../common/components/headings/Headings";
import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";

const ManageVendorsDetail = () => {
  const { 
    formState, 
    errorState, 
    handleSubmit, 
    onFormValueChanged,
    onBack
  } = useManageVendorsDetail();
  
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Vendors Detail</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Member ID for Vendor*"
              name="memberId"
              value={formState.memberId}
              error={errorState.memberId}
              message={errorState.memberId}
              onChange={(value) => { onFormValueChanged('memberId', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBack}>Back</SecondaryButton>
          </div>
        </div>
      </form>
    </Fragment>);
}
export default ManageVendorsDetail;