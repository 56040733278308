import { useState } from 'react';

import AccountMFAData from './AccountMFAData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_MFA_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountMFAData = () => {
  const [accountMFAState, setAccountMFAState] = useState(INITIAL_ACCOUNT_MFA_STATE);

  const postAccountSetMFA = (accessToken, email, textMessage, phoneNumber) => {
    return AccountMFAData.postAccountSetMFAData(accessToken, email, textMessage, phoneNumber, accountMFAState, setAccountMFAState);
  };

  const resetAccountMFAState = () => {
    setAccountMFAState(INITIAL_ACCOUNT_MFA_STATE);
  };

  return {
    accountMFAState,
    resetAccountMFAState,
    postAccountSetMFA
  };
};

export default useAccountMFAData;