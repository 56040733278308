import useForgotPasswordForm from "./UseForgotPasswordForm";

import Input from "../../../inputs/Input";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";

import style from '../LoginForms.module.css';

const ForgotPasswordForm = ({ submitButtonText = 'Continue', secondaryButtonText = 'Back', verticalButtons = false,
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onValueChanged
  } = useForgotPasswordForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label={'Username*'}
            name={'username'}
            value={formState.username}
            error={errorState.username}
            message={errorState.username}
            onChange={(value) => onValueChanged('username', 'username', value)} />
        </div>
        <div className={["col-xs-12 usas-extra-top-margin", style.FormButtons, verticalButtons ? style.Vertical : ''].join(' ')}>
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;