import { useEffect, useState } from "react";

import validate from './LandingLoginFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  username: '',
  password: ''
};

const useLandingLoginForm = (onSubmitFormCallback) => {
  const { formState, errorState, isDirty, handleSubmit, setErrors, updateFormState, setIsDirty
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [hidePasswordState, setHidePasswordState] = useState(true);

  const onValueChanged = (componentLabel, componentName, componentValue) => {
    if (componentValue.trim().length > 0) {
      updateFormState(componentName, componentValue);
      setErrors({ ...errorState, [componentName]: undefined });
    } else {
      updateFormState(componentName, componentValue);
      setErrors({ ...errorState, [componentName]: `${componentLabel} is required` });
    }
  };

  const onToggleHideShowPassword = () => {
    setHidePasswordState(!hidePasswordState);
  };

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    hidePasswordState,
    handleSubmit,
    onValueChanged,
    onToggleHideShowPassword
  };
};

export default useLandingLoginForm;