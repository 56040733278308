import { Fragment } from 'react';

import ManageVendorsUserGroupsGridLarge from './ManageVendorsUserGroupsGridLarge';
import ManageVendorsUserGroupsGridSmall from './ManageVendorsUserGroupsGridSmall';

const ManageVendorsUserGroupsGrid = ({ data, isLoading, onDeleteUserGroup }) => (
  <Fragment>
    <div className='row'>
      <div className="col-xs-12">
        <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>
      </div>
    </div>
    <ManageVendorsUserGroupsGridLarge
      data={data}
      isLoading={isLoading}
      onDeleteUserGroup={onDeleteUserGroup}
    />
    <ManageVendorsUserGroupsGridSmall
      data={data}
      isLoading={isLoading}
      onDeleteUserGroup={onDeleteUserGroup}
    />
  </Fragment>
);

export default ManageVendorsUserGroupsGrid;