import useCrossUINavigation from "../../../../../common/state/crossUINavigation/UseCrossUINavigation";
import { SESSION_DEBUG_TIMER_STORAGE_NAME } from "../../../../../common/utils/Constants";
import { deleteCookie, getCookie, setCookie } from "../../../../../common/utils/CookieUtils";

const useSessionDebugToggle = () => {
  const { navigateToLandingPage } = useCrossUINavigation();

  const onToggleSessionDebugTimer = () => {
    if (getCookie(SESSION_DEBUG_TIMER_STORAGE_NAME)?.enableDebugTimer === true) {
      deleteCookie(SESSION_DEBUG_TIMER_STORAGE_NAME);
      window.location.reload();
    } else {
      setCookie(SESSION_DEBUG_TIMER_STORAGE_NAME, { enableDebugTimer: true });
      window.location.reload();
    }
  };

  const onGoToLandingClicked = () => {
    navigateToLandingPage();
  };

  return {
    isSessionDebugTimerEnabled: getCookie(SESSION_DEBUG_TIMER_STORAGE_NAME)?.enableDebugTimer === true,
    onGoToLandingClicked,
    onToggleSessionDebugTimer
  };
};

export default useSessionDebugToggle;