import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ vendor, onDeleteUserGroup }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{vendor.UserGroupName}</span>&nbsp;
      <div className={global.IconButton}>
        <button className={global.IconButtonMargin}
          type="button"
          onClick={() => onDeleteUserGroup(data)}>
          <DeleteIcon toolTipText='Delete User from Group' />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>User Groups</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{vendor.UserGroupName}</div>
      </div>
    </div>
  </div>
);

const ManageVendorsUserGroupsSmallGrid = ({ data, isLoading, onDeleteUserGroup }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((vendor, i) => (
          <GridRow key={i} vendor={vendor} onDeleteUserGroup={onDeleteUserGroup} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Vendors</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ManageVendorsUserGroupsSmallGrid;