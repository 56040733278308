import { isValidMexicanPhoneNumber, isValidUSOrCanadianPhoneNumber } from "../../../../utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.useEmail !== true && formState.useTextMessage !== true) {
    errors.mfaOptions = 'Must select Email or Text Message';
  } else if (formState.useTextMessage === true) {
    if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format';
    }
  }

  return errors;
};

const MFAEnrollmentFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MFAEnrollmentFormValidation;