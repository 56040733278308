import { Fragment } from "react";
import useManageVendors from "./UseManageVendors";
import ManageVendorsGrid from "./ManageVendorsGrid";

import Headings from "../../../common/components/headings/Headings";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Constants from "../../../common/utils/Constants";

const ManageVendors = () => {
  const { 
    vendorManagementState,
    onAddVendorPerson,
    onManageUserGroups,
    onAddShoppingCart
  } = useManageVendors();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Vendors</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ManageVendorsGrid
            data={vendorManagementState.arrayData}
            isLoading={vendorManagementState.isArrayLoading}
            onAddVendorPerson={onAddVendorPerson}
            onManageUserGroups={onManageUserGroups}
            onAddShoppingCart={onAddShoppingCart}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={vendorManagementState.isArrayLoading} />
    </Fragment>)
}

export default ManageVendors;