import { useContext } from 'react';

import VendorManagementData from './VendorManagementData';
import { VendorManagementStateContext } from './VendorManagementContextProvider';

const useVendorManagementData = () => {
  const [vendorManagementState, setVendorManagementState] = useContext(VendorManagementStateContext);

  const getVendorManagement = () => {
    return VendorManagementData.getVendorManagementData(vendorManagementState, setVendorManagementState);
  };

  const putVendorManagementUserGroup = (userAccountId, userGroupObj) => {
    return VendorManagementData.putVendorManagementUserGroupData(userAccountId, userGroupObj, vendorManagementState, setVendorManagementState);
  };

  const putVendorManagementInfo = (vendorId, memberId) => {
    return VendorManagementData.putVendorManagementInfoData(vendorId, memberId, vendorManagementState, setVendorManagementState);
  }

  const postVendorApiRegistrtation = (vendorId, personId) => {  
    return VendorManagementData.postVendorApiRegistrtationData(vendorId, personId, vendorManagementState, setVendorManagementState);
  }

  return {
    vendorManagementState,
    setVendorManagementState,
    getVendorManagement,
    putVendorManagementUserGroup,
    putVendorManagementInfo,
    postVendorApiRegistrtation
  };
};

export default useVendorManagementData;