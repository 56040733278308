import { API_NAMES, initApi } from "../../../utils/HttpApiHelper";

const postAccountChangePasswordData = (href, newPassword, recoveryCode, state, setState) => {
  if (href && newPassword && recoveryCode) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/ChangePassword`;
    const payload = {
      href: href,
      newPassword: newPassword,
      recoveryCode: recoveryCode
    };

    return api?.executeObject ? api.executeObject(url, 'POST', payload) : null;
  }
};

const AccountChangePasswordData = {
  postAccountChangePasswordData
};

export default AccountChangePasswordData;