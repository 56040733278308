import { useEffect, useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import {
  AC_STEP_EXISTING_ACCOUNT, AC_STEP_EXPIRED_PASSWORD, AC_STEP_FORGOT_PASSWORD, AC_STEP_FORGOT_USERNAME, AC_STEP_MFA, AC_STEP_ONE_TIME_PASSWORD, AC_STEP_USER_INFO
} from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useAccountLoginData from "../../../../../../../common/state/account/accountLogin/UseAccountLoginData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationLogin = () => {
  const [searchParams,] = useSearchParams();
  const { updateAccountCreationStep } = useAccountCreationData();
  const { accountLoginState, postAccountLogin, resetAccountLoginState
  } = useAccountLoginData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onForgotUsernameClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_USERNAME);
  };

  const onForgotPasswordClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_PASSWORD);
  };

  const onCreateALoginClicked = () => {
    updateAccountCreationStep(AC_STEP_EXISTING_ACCOUNT);
  };

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountLoginState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const username = formState.username;
    const password = formState.password;

    const postAccountLoginPromise = postAccountLogin(username, password);

    if (postAccountLoginPromise ?? false) {
      postAccountLoginPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accountLockedOut === true) {
            const minutes = Math.ceil(newState.objData?.secondsUntilUnlock / 60);

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`
            });
          } else if (newState.objData?.mustEnrollInMFA === true) {
            const loginData = {
              accessToken: newState.objData?.accessToken,
              emailAddress: newState.objData?.emailAddress
            };

            updateAccountCreationStep(AC_STEP_MFA, loginData, 'loginData');
          } else if (newState.objData?.mustChangePassword === true) {
            const loginData = {
              href: newState.objData?.href,
              username: username
            };

            updateAccountCreationStep(AC_STEP_EXPIRED_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The username and/or password is invalid.'
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  // skip to registration if parent is creating account for child
  useEffect(() => {
    const memberId = searchParams.get('memberId');

    if (memberId) {
      const childData = {
        memberId,
        firstName: searchParams.get('firstName'),
        lastName: searchParams.get('lastName'),
        middleName: searchParams.get('middleName'),
        preferredName: searchParams.get('preferredName'),
        birthDate: searchParams.get('birthDate')
      };

      updateAccountCreationStep(AC_STEP_USER_INFO, childData, 'childData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: accountLoginState.isObjLoading,
    loginErrorPopupState,
    onSubmitFormCallback,
    onForgotUsernameClicked,
    onForgotPasswordClicked,
    onCreateALoginClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationLogin;