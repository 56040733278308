import { Fragment } from "react";
import useManageVendorsUserGroups from "./UseManageVendorsUserGroups";
import ManageVendorsUserGroupsGrid from "./ManageVendorsUserGroupsGrid";

import Headings from "../../../common/components/headings/Headings";
import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Constants from "../../../common/utils/Constants";

const ManageVendorsUserGroups = () => {
  const {
    accountManagementUserGroupState,
    data, 
    onAddUserGroup, 
    onDeleteUserGroup, 
    onBack } = useManageVendorsUserGroups();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Vendors User Groups</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PrimaryButton type="button" onClick={onAddUserGroup}>Assign User Group</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <ManageVendorsUserGroupsGrid
            data={data}
            isLoading={false}
            onDeleteUserGroup={onDeleteUserGroup}
          />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onBack}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={accountManagementUserGroupState.isArrayLoading} />
    </Fragment>);
}
export default ManageVendorsUserGroups;