import { useState } from "react";

import { AC_STEP_CHANGE_PASSWORD } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useAccountForgotPasswordData from "../../../../../../../common/state/account/accountForgotPassword/UseAccountForgotPasswordData";

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationForgotPassword = () => {
  const { updateAccountCreationStep, resetAccountCreationState } = useAccountCreationData();
  const { accountForgotPasswordState, postAccountForgotPassword, resetAccountForgotPasswordState
  } = useAccountForgotPasswordData();
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountForgotPasswordState();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const username = formState.username;

    const postAccountForgotPasswordPromise = postAccountForgotPassword(username);

    if (postAccountForgotPasswordPromise ?? false) {
      postAccountForgotPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.href) {
            updateAccountCreationStep(AC_STEP_CHANGE_PASSWORD, { href: newState.objData.href, username: username }, 'forgotPasswordData');
          } else {
            setAPIErrorPopupState({
              ...apiErrorPopupState,
              displayPopup: true
            });
          }
        }
      }).catch((e) => {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountForgotPasswordState.isObjLoading,
    apiErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
  };
};

export default useAccountCreationForgotPassword;