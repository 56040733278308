import { useState } from 'react';

import AccountForgotUsernameData from './AccountForgotUsernameData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_FORGOT_USERNAME_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountForgotUsernameData = () => {
  const [accountForgotUsernameState, setAccountForgotUsernameState] = useState(INITIAL_ACCOUNT_FORGOT_USERNAME_STATE);

  const postAccountForgotUsername = (emailAddress) => {
    return AccountForgotUsernameData.postAccountForgotUsernameData(emailAddress, accountForgotUsernameState, setAccountForgotUsernameState);
  };

  const resetAccountForgotUsernameState = () => {
    setAccountForgotUsernameState(INITIAL_ACCOUNT_FORGOT_USERNAME_STATE);
  };

  return {
    accountForgotUsernameState,
    resetAccountForgotUsernameState,
    postAccountForgotUsername
  };
};

export default useAccountForgotUsernameData;