import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const VendorManagementStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const VendorManagementContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <VendorManagementStateContext.Provider value={stateHook}>
      {children}
    </VendorManagementStateContext.Provider>
  );
};

export default VendorManagementContextProvider;