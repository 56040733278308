import { useState } from 'react';

import AccountForgotPasswordData from './AccountForgotPasswordData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_FORGOT_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountForgotPasswordData = () => {
  const [accountForgotPasswordState, setAccountForgotPasswordState] = useState(INITIAL_ACCOUNT_FORGOT_PASSWORD_STATE);

  const postAccountForgotPassword = (username) => {
    return AccountForgotPasswordData.postAccountForgotPasswordData(username, accountForgotPasswordState, setAccountForgotPasswordState);
  };

  const resetAccountForgotPasswordState = () => {
    setAccountForgotPasswordState(INITIAL_ACCOUNT_FORGOT_PASSWORD_STATE);
  };

  return {
    accountForgotPasswordState,
    resetAccountForgotPasswordState,
    postAccountForgotPassword
  };
};

export default useAccountForgotPasswordData;