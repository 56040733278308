/* eslint-disable react/no-unescaped-entities */
import { Fragment } from "react";

import useAccountCreationMFA from "./UseAccountCreationMFA";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import MFAEnrollmentForm from "../../../../../../../common/components/forms/login/mfaEnrollmentForm/MFAEnrollmentForm";

import Constants from "../../../../../../../common/utils/Constants";

import style from '../../../../LandingPages.module.css';

const AccountCreationMFA = () => {
  const {
    isLoading,
    loginErrorPopupState,
    emailAddress,
    onCancelClicked,
    onCloseLoginErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationMFA();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Multi-Factor Authentication</Headings.H3>
          <p>Let's add an extra layer of protection to keep your login safe. From now on, we will send you a code to make sure it is you.</p>
          <MFAEnrollmentForm
            submitButtonText={'Submit'}
            secondaryButtonText={'Cancel'}
            emailAddress={emailAddress}
            verticalButtons={true}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onCancelClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationMFA;