import { Fragment } from 'react';

import AlreadyLinkedSpan from '../dialogs/AlreadyLinkedSpan';

import ActionIntraPageButton from '../../../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../../../common/utils/DateFunctions';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ child }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}><span className='hidden-xs'>{child.firstName || ''}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Child Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{child.firstName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{child.organizationName || <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ personChildren }) => (
  <div className={global.SmallTableDetailTable}>
    {personChildren.map((child, i) =>
      <DetailTableRow key={i} child={child} />)
    }
  </div>
);

const TableRow = ({ personDup, onLinkPersonClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        {personDup.isAlreadyLinked === true
          ? (
              <AlreadyLinkedSpan toolTipText={`This person has already been linked to an existing account with the email: ${personDup.personInfo?.censoredExistingAccountEmail}`} />
          ) : (
            <ActionIntraPageButton type="button" onClick={() => onLinkPersonClicked(personDup.personId)}>Link to this Person</ActionIntraPageButton>
          )
        }
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{personDup.personInfo?.firstName || ''} {personDup.personInfo?.lastName || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{personDup.personInfo?.birthDate ? formatDate(personDup.personInfo.birthDate) : <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Comp. Category</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{personDup.personInfo?.competitionCategoryName || <span>&nbsp;</span>}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{personDup.personInfo?.organizationName || <span>&nbsp;</span>}</div>
        </div>
      </div>
    </div>
    {Array.isArray(personDup.personInfo?.children) && personDup.personInfo?.children?.length > 0 &&
      <DetailTable personChildren={personDup.personInfo.children} />
    }
  </Fragment>
);

const PersonDupGridSmall = ({ data, isLoading, onLinkPersonClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : data.length > 0
        ? data.map((personDup, i) => <TableRow key={i} personDup={personDup} onLinkPersonClicked={onLinkPersonClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Duplicate Persons</div>
            </div>
          </div>
        )
    }
  </div>
);

export default PersonDupGridSmall;