import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import { AC_STEP_ONE_TIME_PASSWORD } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

import useAccountExpiredPasswordData from "../../../../../../../common/state/account/accountExpiredPassword/UseAccountExpiredPasswordData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationExpiredPassword = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, resetAccountCreationState, updateAccountCreationStep } = useAccountCreationData();
  const { accountExpiredPasswordState, postAccountResetPassword, resetAccountExpiredPasswordState
  } = useAccountExpiredPasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountExpiredPasswordState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const href = accountCreationState.loginData.href;
    const currentPassword = formState.currentPassword;
    const newPassword = formState.newPassword;

    const postAccountResetPasswordPromise = postAccountResetPassword(href, currentPassword, newPassword);

    if (postAccountResetPasswordPromise ?? false) {
      postAccountResetPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.errorMessage !== null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountExpiredPasswordState.isObjLoading,
    username: accountCreationState.loginData?.username,
    loginErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationExpiredPassword;