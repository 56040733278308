import { Fragment } from "react";

import useAccountCreationExistingAccount from "./UseAccountCreationExistingAccount";

import ExistingAccountForm from "../../components/forms/ExistingAccountForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import ActionIntraPageButton from "../../../../../../../common/components/buttons/ActionIntraPageButton";

import style from '../../../../LandingPages.module.css';

const AccountCreationExistingAccount = () => {
  const {
    existingAccountData,
    displayRecoveryPopupState,
    onCancelClicked,
    onSubmitFormCallback,
    onForgotUsernameClicked,
    onForgotPasswordClicked
  } = useAccountCreationExistingAccount();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Existing Account</Headings.H3>
          <p>Before you continue, please confirm whether or not you have an existing account.</p>
          <ExistingAccountForm
            existingAccountData={existingAccountData}
            secondaryButtonText={'Cancel'}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onCancelClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Recover Account'}
        displayPopUp={displayRecoveryPopupState === true}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6">
            <PrimaryButton className={style.BigButton} type={'button'} onClick={onForgotUsernameClicked}>Forgot Username</PrimaryButton>
          </div>
          <div className="col-xs-12 col-sm-6">
            <ActionIntraPageButton className={style.BigButton} type={'button'} onClick={onForgotPasswordClicked}>Forgot Password</ActionIntraPageButton>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCancelClicked}>Return To Login</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default AccountCreationExistingAccount;