import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const getVendorManagementData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = '/Vendor/Management';
  
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
}

const putVendorManagementUserGroupData = (userAccountId, userGroupObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/Vendor/VendorAccountUserGroup/UserAccount/${userAccountIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'PUT', userGroupObj) : null;
  }
};

const putVendorManagementInfoData = (vendorId, memberId, state, setState) => {
  if (vendorId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const memberIdForUrl = encodeURIComponent(memberId);
    const url = `/Vendor/VendorManagementInfo/${vendorId}/?memberId=${memberIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'PUT') : null;
  }
};

const postVendorApiRegistrtationData = (vendorId, personId, state, setState) => { 
  if (vendorId > 0) { 
    const api = initApi(API_NAMES.COMMON, state, setState); 
    const url = `/Vendor/VendorApiRegistration/${vendorId}/?personId=${personId}`; 
    
    return api?.executeArray ? api.executeArray(url, 'POST') : null; 
  }
};

const VendorManagementData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getVendorManagementData,
  putVendorManagementUserGroupData,
  putVendorManagementInfoData,
  postVendorApiRegistrtationData
};

export default VendorManagementData;